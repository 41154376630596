(function(){
	let owlSlider = $('.owl-carousel');

	if(owlSlider.length > 0){
		let js = document.createElement('script');

		js.src = 'assets/js/owl.carousel.min.js';

		document.body.appendChild(js);

		js.addEventListener('load', function(){
			owlCarouselReady();
		});
	}
})();

function owlCarouselReady(){

	$('.owl-carousel').each(function(){
		let responsiveList 	= {};
		let $this = $(this);

		responsiveList.xs 	= $this.data('xs') || 1;
		responsiveList.sm 	= $this.data('sm') || responsiveList.xs;
		responsiveList.md 	= $this.data('md') || responsiveList.sm;
		responsiveList.lg 	= $this.data('lg') || responsiveList.ms;
		responsiveList.xl 	= $this.data('xl') || responsiveList.lg;

		let childrenLength 	= $this.children().length;
		let margin 			= $this.data('margin') || 0;
		let autoplay 		= $this.data('autoplay');
		let nav 			= $this.data('nav');
		let prev			= $this.data('prev') || 'fas fa-chevron-left';
		let next			= $this.data('next') || 'fas fa-chevron-right';
		let loop 		    = $this.data('loop') || false
		let navText			= [
			`<i class="${prev}"></i><span class="sr-only">Prev</span>`,
			`<i class="${next}"></i><span class="sr-only">Next</span>`,
		];

		$this.owlCarousel({
			autoplay	: autoplay,
			nav: nav,
			navText: navText,
			responsive 	: {
				0 : {
					items 	: responsiveList.xs,
					loop  	: loop ? childrenLength > responsiveList.xs : false,
					margin	: margin || 0
				},
				576 : {
					items 	: responsiveList.sm,
					loop  	: loop ? childrenLength > responsiveList.sm : false,
					margin	: margin || 0
				},
				768 : {
					items 	: responsiveList.md,
					loop  	: loop ? childrenLength > responsiveList.md : false,
					margin	: margin || 0
				},
				992 : {
					items 	: responsiveList.lg,
					loop  	: loop ? childrenLength > responsiveList.lg : false,
					margin	: margin || 0
				},
				1200 : {
					items 	: responsiveList.xl,
					loop  	: loop ? childrenLength > responsiveList.xl : false,
					margin	: margin || 0
				},
			}
		});
	});


	$('[data-owl]').click(function(e){
		e.preventDefault();

		let owl 	= $this.data('owl');
		let target	= $this.attr('href');

		$(target).trigger(owl+'.owl.carousel');
	});
}
