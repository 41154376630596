$('.toggle-quantidade').each(function(){
	var $this 		= $(this)
	var input 		= $this.find('input');
	var btn 		= $this.find('.btn');
	var isNotNumber = (val, callback) => {
		if(isNaN(val)){
			$(this).val(val.replace(val,''))

			Alerta.new({
				variant: 'error',
				message: `Ops.... <br>
				Você atribuiu um valor não numérico para a quantidade do produto
				`
			});

			if(callback){
				callback();
			}
		}

		return false;
	}

	btn.click(function(){
		var fn = $(this).data('fn');
		var vl = input.val();

		isNotNumber(vl);

		vl = parseInt(vl)

		switch(fn) {
			case 'minus':
				if(vl > 1){
					input.val(vl - 1);
				}
			break;
			case 'plus':
				input.val(vl + 1)
			break;
		}
	});

	input.keyup(function(e){
		var val = $(this).val();

		isNotNumber(e.key);
	});

	input.blur(function(){
		if($(this).val() == ''){
			$(this).val(1);
		}
	});
});

$('body').click(function(){
	$('.toggle-tamnho').removeClass('open');
});

$('.toggle-tamnho').each(function(){
	var $this 	= $(this);
	var input 	= $this.find('input');
	var label 	= $this.find('label');
	var options = $this.find('.options li');

	label.click(function(e){
		e.stopPropagation();
		$('.toggle-tamnho').removeClass('open');
		$this.addClass('open');
	});

	options.click(function(){
		var value 	= $(this).data('value');
		var text 	= $(this).text();

		input.val(value);
		label.text(text);
		$this.toggleClass('open');
	})
});