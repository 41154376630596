$('[data-toggle="tabs"]').click(function(e){
	e.preventDefault();
	let parent = $(this).parents('.tabs-datalhe-produto');
	let target = $(this).data('target');
	let father = $(this).parent('li');

	parent.find('.tab-item:not('+target+')').removeClass('active');
	$(target).addClass('active');
	$(father).siblings('li').removeClass('active');
	$(father).addClass('active');
});

$(document).click(function(e){
	$('.config-produto .tamanho').removeClass('focus');
})

$('.config-produto .tamanho').each(function(){
	let self 	= $(this);
	let input 	= self.find('input');
	let output 	= self.find('.form-control');
	let label	= self.find('label');
	let options = self.find('.options li');

	if(input.val() != ''){
		self.addClass('selected');

		output.text(self.find('li.active').text());
	}else{
		self.removeClass('selected');
	}

	self.click(function(e){
		e.stopPropagation();
	});

	self.find('.form-control, label').click(function(){
		self.addClass('focus')
	});

	options.click(function(){
		let value = $(this).data('value');
		let text = $(this).text();

		options.removeClass('active');
		$(this).addClass('active');

		input.val(value);
		output.text(text);

		self.addClass('selected').removeClass('focus');
	});

});

$('.config-produto .quantidade').each(function(){
	let self 	= $(this);
	let label 	= self.find('label');
	let input 	= self.find('input');
	let btn		= self.find('.btn');

	btn.click(function(){
		let fn = $(this).data('fn');
		let val = parseInt(input.val());
		let max = parseInt(input.attr('max')) || 10**23;

		switch(fn) {
			case 'minus':
				if(val > 1){
					input.val(val-1);
				}
			break;
			case 'plus':
				if(val === max){
					return false;
				}

				input.val(val+1)
			break;
		}
	});

	input.keyup(function(e){
		var val = $(this).val();

		if(isNaN(e.key)){
			$(this).val(val.replace(e.key,''));
		}
	});

	input.blur(function(){
		var val = $(this).val();

		if(val === ''){
			$(this).val(1);
		}
	});
});

$('.form-valiar label').click(function(){
	$('.form-valiar .form-group').removeClass('checked');
	$(this).parent().addClass('checked');
});