Object.defineProperty(window, 'midiaSize', {
	get() {
		let w = window.innerWidth;
		let s = 'xs';

		if(w < 576) {
			s = 'xs';
		}

		if(w >= 576) {
			s = 'sm';
		}

		if(w >= 768) {
			s = 'md';
		}

		if(w >= 992) {
			s = 'lg';
		}

		if(w >= 1200) {
			s = 'xl';
		}

		return s
	}
});

const Alerta = {
	new : function(config){
		let template = `<div class="alert alert-{{variant}} alert-dismissible">
            <div class="media">{{icon}}
                <div class="media-body">{{message}}</div>
            </div>
            <button class="close" type="button" aria-label="Fechar" data-dismiss="alert">×</button>
        </div>`;
        let alertas = $('.alertas');

        if(!config.icon){
        	config.icon = '';
        }

        if(alertas.length == 0) {
        	let divAlertas = document.createElement('div');

        	$(divAlertas).addClass('alertas');
        	document.body.appendChild(divAlertas);
        }

        let alerta = template.replace(/{{message}}/g,config.message);

        alerta = alerta.replace(/{{variant}}/g, config.variant);
        alerta = alerta.replace(/{{icon}}/g, config.icon);


        $('.alertas').append(alerta);
	},
	start: (function(){
		let style = document.createElement('style');

		style.innerText = '.alertas{position:fixed;right:15px;top:90px;max-width:calc(100% - 30px);z-index:999}.alertas .fas,.alertas .fab,.alertas .far{margin-right:15px;display: block}.alertas .media-body{align-self:center}.alertas .fa-2x{margin-right:10px}.alertas .alert{border-radius:0;border-color:rgba(0,0,0,.3);box-shadow:0 0 8px rgba(0,0,0,.5);max-width:300px;animation:rubberBand 0.6s linear;background:#FFF}.alertas .alert-success{background:#388E3C;color:#FFF}.alertas .alert-warning{background:#F57C00;color:#FFF}.alertas .alert-danger,.alertas .alert-error{background:#D32F2F;color:#FFF}.alertas .alert-info{background:#1976D2;color:#FFF}.alertas .alert-dark{background:#000;color:#FFF}.alertas .alert-light{background:#FFF;color:#000}.alertas .alert-purple{background:#7B1FA2;color:#fff}.alertas .alert-teal{background:#00796B;color:#fff}';

		document.head.appendChild(style)
	})()
};